@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700;900&display=swap');

body, html {
    font-family: 'Raleway', sans-serif;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sparks-image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 4;
    opacity: 0.4;
    pointer-events: none;
}

@media screen and (min-width: 0px){
    .sparks-image{
        max-width: 100%;
    }
}

@media screen and (min-width: 376px){
    .sparks-image{
        max-width: 120%;
    }
}

@media screen and (min-width: 600px){
    .sparks-image{
        width: 80%;
        max-width: 100%;
    }
}

@media screen and (min-width: 900px){
    .sparks-image{
        max-width: 90%;
        width: 40%;
    }
}
@media screen and (min-width: 900px) and (min-height: 1000px){
    .sparks-image{
        max-width: 90%;
        width: 80%;
    }
}

@media screen and (min-width: 1200px){
    .sparks-image{
        max-width: 90%;
        width: 40%;
    }
}

@media screen and (min-width: 1400px){
    .sparks-image{
        max-width: 90%;
        width: 40%;
    }
}

@media screen and (min-width: 1700px){
    .sparks-image{
        max-width: 90%;
        width: 40%;
    }
}

@keyframes bounce-2 {
    0%   { transform: translateX(0); }
    50%  { transform: translateX(10px); }
    100% { transform: translateX(0); }
}
.triangle {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: bounce-1;
    animation-timing-function: linear;
}

@keyframes bounce-1{
    0%,20%,60%,
    to{transform:translateX(0)}
    40%{transform:translateX(10px)}
    80%{transform:translateX(5px)}
}
